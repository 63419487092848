.charity {
    display: flex;

    .content_first {
        div {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    
    button {
        border-color: gold;
        border-radius: 14px;
        width: fit-content;
        letter-spacing: 4px;
        line-height: 18px;
        padding: 10px;
        color: #fbcc00;
        background-color: rgba(6, 30, 44, 0.753);
        font-weight: bold;
        cursor: pointer;

        span {
            color: inherit;
        }

        i {
            color: #fbcc00;
            font-family: sans-serif;
            font-weight: 600;
            margin-right: -2px;
            margin-left: -4px;
        }
    }
}

@media (max-width: 1573px) {
    .charity {
        .content_first {
            
        }
    }
}