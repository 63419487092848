.home {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .home_wrapper {
        width: 1100px;
        height: 90%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        // border: 1px solid red;
        .circle {
            width: 50rem;
            height: 50rem;
            // border: 1px solid red;
            border-radius: 50%;
            background-image: url("../../../public/images/seadawg.png");
            background-size: cover;
            background-position: center;
            position: relative;
            .navs {
                position: absolute;
                font-size: 1.8rem;
                font-weight: 400;
                font-family: "Roboto Slab", serif;
                &:hover {
                    color: grey;
                }
            }
            .text:hover {
                color: grey;
                font-size: 1.9rem;
            }
            .nav_1 {
                bottom: 25%;
                transform: rotate(-122deg) translateY(-10rem);
                z-index: 0;
            }
            .nav_2 {
                bottom: 20%;
                left: 10.5%;
                transform: rotate(180deg) translateY(-10rem);
                z-index: 1;
                &:hover {
                    color: grey;
                    font-size: 1.9rem;
                }
            }
            .nav_3 {
                right: 0;
                bottom: 25%;
                transform: rotate(122deg) translateY(-10rem);
            }
        }
        .links {
            width: 100%;
            height: 5rem;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .link_shop {
                padding-left: 73px !important;
                padding-right: 73px !important;
            }

            .link_teaser {
                text-decoration: none;
                font-family: inherit;
                color: inherit;
                padding: 5px 0px;
            }

            // Links
            .active_link {
                background-color: white;
                span {
                    color: black;
                }
            }

            span {
                padding: 6px 60px;
            }

            .link {
                width: 20rem;
                height: 4rem;
                border: 1px solid white;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border-radius: 0.3rem;
                span {
                    font-size: 2rem;
                    font-family: "Roboto Slab", serif;
                    font-weight: 200;
                }
            }
        }
    }
}

@media (max-width: 1100px) {
    .home {
        .home_wrapper {
            width: 100%;
        }
    }
}

@media (max-width: 630px) {
    .home {
        .home_wrapper {
            transform: scale(0.7);
        }
    }
}

@media (max-width: 495px) {
    .home {
        .home_wrapper {
            transform: scale(0.6);

            .links {
                margin-top: 50px;
                width: 500px;
            }
        }
    }
}

@media (max-width: 376px) {
    .home {
        .home_wrapper {
            .links {
                margin-top: 50px;
            }
        }
    }
}

@media (max-height: 361px) {
    .home {
        .home_wrapper {
            transform: scale(0.5);
        }
    }
}
