.collaboration {
    display: flex;

    .highlight_yellow {
        font-weight: bold;

        a {
            color: #fbcc00;
            text-decoration: none;
        }
    }
}
