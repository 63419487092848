.our_story_nav {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9));
    animation: fade 1000ms ease-in forwards 1;
    z-index: 5;
    .our_story_nav_wrapper {
        width: 50rem;
        height: 50rem;
        border: 1px solid rgba(255, 255, 255, 0.534);
        border-radius: 50%;
        display: flex;
        margin-top: 10px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 14rem;
        gap: 30px;
        position: relative;
        .heading {
            font-size: 7rem;
            //   height: 15rem;
            font-family: "Roboto Slab", serif;
        }
        .links_div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            //   border: 1px solid red;
            height: 28%;

            .nav_link {
                text-decoration: none;
            }

            .nav_link_three {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;

                height: 65%;
            }

            span {
                font-size: 2rem;
                margin-top: 2rem;
                border-bottom: 2px solid rgb(255, 255, 255);
                cursor: pointer;
                animation-name: links;
                animation-duration: 3s;
                animation-iteration-count: infinite;
            }
        }
        .close {
            position: absolute;
            top: 0rem;
            right: -1rem;
            font-size: 2.5rem;
            color: white;
            cursor: pointer;
        }
    }
}

@keyframes links {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@media (max-width: 630px) {
    .our_story_nav {
        .our_story_nav_wrapper {
            width: 36rem;
            height: 36rem;
            margin-top: 55px;

            .heading {
                font-size: 3.4rem;
            }

            .links_div {
                height: 20%;

                span {
                    font-size: 1rem;
                }
            }
        }
    }
}

@media (max-width: 495px) {
    .our_story_nav {
        .our_story_nav_wrapper {
            margin-top: 46px;
            height: 30rem;
            width: 30rem;

            .heading {
                font-size: 3.4rem;
            }

            .links_div {
                height: 37%;

                span {
                    font-size: 1.5rem;
                }
            }
        }
    }
}

@media (max-width: 376px) {
    .our_story_nav {
        .our_story_nav_wrapper {
            width: 30rem;
            height: 30rem;
            margin-top: 46px;

            .heading {
                font-size: 2.8rem;
            }
        }
    }
}

// Queries height //
// @media (max-height: 885px) {
//     .our_story_nav {
//         .our_story_nav_wrapper {
//             margin-top: 60px;
//         }
//     }
// }

// @media (max-height: 795px) {
//     .our_story_nav {
//         .our_story_nav_wrapper {
//             margin-top: 40px;
//         }
//     }
// }
// @media (max-height: 750px) {
//     .our_story_nav {
//         .our_story_nav_wrapper {
//             margin-top: 50px;
//         }
//     }
// }

// @media (max-height: 700px) {
//     .our_story_nav {
//         .our_story_nav_wrapper {
//             margin-top: 80px;
//         }
//     }
// }

// @media (max-height: 650px) {
//     .our_story_nav {
//         .our_story_nav_wrapper {
//             margin-top: 80px;
//         }
//     }
// }

// @media (max-height: 600px) {
//     .our_story_nav {
//         .our_story_nav_wrapper {
//             margin-top: 80px;
//         }
//     }
// }

@media screen and (max-height: 884px) and (max-width: 1639px) {
    .our_story_nav {
        .our_story_nav_wrapper {
            margin-top: 10px;
        }
    }
}

@media screen and (max-height: 850px) and (max-width: 1669px) {
    .our_story_nav {
        .our_story_nav_wrapper {
            margin-top: 20px;
        }
    }
}

@media screen and (max-height: 850px) and (max-width: 1669px) {
    .our_story_nav {
        .our_story_nav_wrapper {
            margin-top: 40px;
        }
    }
}

@media screen and (max-height: 800px) and (max-width: 1669px) {
    .our_story_nav {
        .our_story_nav_wrapper {
            margin-top: 50px;
        }
    }
}

@media screen and (max-height: 750px) and (max-width: 1669px) {
    .our_story_nav {
        .our_story_nav_wrapper {
            margin-top: 60px;
        }
    }
}

@media screen and (max-height: 700px) and (max-width: 1669px) {
    .our_story_nav {
        .our_story_nav_wrapper {
            margin-top: 80px;
        }
    }
}


@media screen and (max-height: 650px) and (max-width: 1669px) {
    .our_story_nav {
        .our_story_nav_wrapper {
            margin-top: 90px;
        }
    }
}


@media screen and (max-height: 883px) and (max-width: 630px) {
    .our_story_nav {
        .our_story_nav_wrapper {
            margin-top: 70px;
        }
    }
}

@media screen and (max-height: 800px) and (max-width: 630px) {
    .our_story_nav {
        .our_story_nav_wrapper {
            margin-top: 80px;
        }
    }
}

@media screen and (max-height: 750px) and (max-width: 630px) {
    .our_story_nav {
        .our_story_nav_wrapper {
            margin-top: 90px;
        }
    }
}

@media screen and (max-height: 695px) and (max-width: 630px) {
    .our_story_nav {
        .our_story_nav_wrapper {
            margin-top: 100px;
        }
    }
}

@media screen and (max-height: 605px) and (max-width: 630px) {
    .our_story_nav {
        .our_story_nav_wrapper {
            margin-top: 120px;
        }
    }
}


@media screen and (max-height: 883px) and (max-width: 495px) {
    .our_story_nav {
        .our_story_nav_wrapper {
            margin-top: 50px;
        }
    }
}


@media screen and (max-height: 800px) and (max-width: 495px) {
    .our_story_nav {
        .our_story_nav_wrapper {
            margin-top: 60px;
        }
    }
}


@media screen and (max-height: 750px) and (max-width: 495px) {
    .our_story_nav {
        .our_story_nav_wrapper {
            margin-top: 70px;
        }
    }
}

@media screen and (max-height: 690px) and (max-width: 495px) {
    .our_story_nav {
        .our_story_nav_wrapper {
            margin-top: 80px;
        }
    }
}