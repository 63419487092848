.intro {
    display: flex;
}

.comic-creds {
    color: white;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 60px;
    font-size: 14px;
    font-weight: 500;

    i {
        color: rgb(161, 161, 161);
    }
}

.comic_container {
    width: 1240px;
    height: max-content;
    margin-left: 330px;
    margin-bottom: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.comic_stripe_container {
    width: 1120px;
    height: 3680px;
    padding-left: 10px;
    padding-top: 10px;
    margin-top: 80px;
    justify-content: space-around;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(8, 1fr);

    .comic_stripe_element {
        height: 360px;
        width: 360px;
        background-color: rgb(255, 255, 255);

        .comic_stripe_image {
            height: 360px;
            width: 360px;
        }
    }
}


// QUERIES //

@media (min-width: 2000px) {
    .comic_container {
        margin-left: 550px;
    }
}

@media (max-width: 1669px) {
    .comic_container {
        margin-left: 200px;
    }
}

@media (max-width: 1599px) {
    .comic_container {
        margin-left: 150px;
    }
}

@media (max-width: 1282px) {
    .comic_container {
        margin-left: 100px;
    }
}

@media (max-width: 1084px) {
    .comic_stripe_container {
        height: 10100px;
        width: 420px;
        gap: 5px;
        display: flex;
        flex-direction: column;

        .comic_stripe_element {
            height: 400px;
            width: 400px;

            .comic_stripe_image {
                height: 400px;
                width: 400px;
            }
        }

        .comic_stripe_element:nth-child(3n) {
            margin-bottom: 40px;
        }
    }

    .comic-creds {
        margin-bottom: 60px;
        font-size: 12px;
        width: 60%;
        font-weight: 500;
    }
}

@media (max-width: 538px) {
    .comic_stripe_container {
        height: 8150px;
        width: 340px;
        margin-left: -20px;

        .comic_stripe_element {
            height: 320px;
            width: 320px;

            .comic_stripe_image {
                height: 320px;
                width: 320px;
            }
        }
    }
}

@media (max-width: 438px) {
    .comic_stripe_container {
        width: 240px;
        height: 5920px;
        margin-left: -60px;


        .comic_stripe_element {
            height: 220px;
            width: 220px;

            .comic_stripe_image {
                height: 220px;
                width: 220px;
            }
        }

        .comic_stripe_element:nth-child(3n) {
            margin-bottom: 40px;
        }
    }

    .comic-creds {
    margin-left: 100px !important;

    i {
        color: rgb(161, 161, 161);
    }
}
}

@media (max-width: 380px) {
    .comic-creds {
        margin-left: 80px !important;
    }
}