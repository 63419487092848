.home_button_container {
    position: fixed;
    width: 4000px;
    display: flex;
    align-items: center;
    height: 80px;
    background-color: black;
    z-index: 20;

    .home_button {
        font-size: 28px;
        margin-left: 36px;
        cursor: pointer;
        position: absolute;
        z-index: 100;
    }
}

// Not needed Yet

// @media (max-width: 1206px) {
// }

// @media (max-width: 1000px) {
// }

// @media (max-width: 800px) {
// }

// @media (max-width: 898px) {
//

// @media (max-width: 749px) {
// }

 @media (max-width: 595px) {
    .home_button_container {
        .home_button {
            margin-left: 14px;
        }
    }
 }

@media (max-width: 376px) {
    .home_button_container {
        .home_button {
            margin-left: 14px;
        }
    }
}
