.our-brands {
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    // Header
    .brands-header {
        height: 19%;
        width: 100%;

        display: flex;
        padding: 0px 20px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        .brands-title-first,
        .brands-title-second {
            font-size: 2.6rem;
            text-align: center;
        }

        .brands-title-first {
            color: #fbcc00;
        }

        .brands-title-second {
            color: #3ba7ff;
        }
    }

    // Main Content
    .brands-main {
        width: 80%;
        height: 100%;
        margin: 0 auto;

        display: flex;
        justify-content: center;
        gap: 40px;
        align-items: center;

        .brands-left,
        .brands-right {
            height: 600px;
            // min-width: 500px;
            // max-width: 500px;
            width: 500px;
            padding: 0px 60px;
            transition: all 0.8s ease-in-out;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .brand-card-left,
            .brand-card-right {
                width: 100%;
                height: 80%;
                border: 10px solid #3ba7ff;
                border-radius: 36px;
                cursor: pointer;

                display: flex;
                justify-content: center;
                align-items: center;

                position: relative;

                .brand-card-image {
                    width: 100%;
                }
            }

            .brand-card-right {
                border-color: #fbcc00;
            }

            .brands-left-link,
            .brands-right-link {
                text-align: center;
                font-size: 5rem;
                color: #3ba7ff;
                text-transform: uppercase;
                font-weight: 500;
                animation: glow 3s infinite;
                text-decoration-thickness: 2px;
                text-underline-offset: 6px;
                transition: all 0.8s ease-in-out;
            }

            .brands-right-link {
                color: #fbcc00;
            }
        }

        .brands-left-inactive {
            padding: 0px !important;
            width: 100px !important;
            min-width: 100px !important;
            overflow: hidden;

            .brands-left-link {
                visibility: hidden !important;
            }
        }

        .brands-right-inactive {
            padding: 0px !important;
            width: 100px !important;
            min-width: 100px !important;
            overflow: hidden;

            .brands-right-link {
                visibility: hidden !important;
            }
        }

        .brands-seperator {
            height: 500px;
            width: 100px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .seperator-dots {
                width: 10px;
                height: 10px;
                background-color: rgb(87, 87, 87);
                border-radius: 50%;
            }

            .seperator-line-left,
            .seperator-line-right {
                height: 35%;
                width: 10px;
                background-color: rgb(87, 87, 87);
                border-radius: 50%;
            }
        }
    }
}

.button_right,
.button_left {
    height: 50px;
    width: 50px;
    bottom: 16px;
    border-radius: 16px;
    position: absolute;

    &:hover {
        background-color: crimson;
    }
}

.button_left {
    background-color: #3ba7ff;
}

.button_right {
    background-color: #fece00;
}

@keyframes glow {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.5;
    }
}

// Queries

@media (max-width: 1024px) {
    .our-brands {
        .brands-main {
            width: 100%;
            .brands-left,
            .brands-right {
                min-width: 400px;
                max-width: 400px;
                height: 500px;

                .brands-left-link,
                .brands-right-link {
                    font-size: 4rem;
                }
            }
        }
    }
}

@media (max-width: 812px) {
    .our-brands {
        .brands-main {
            .brands-left,
            .brands-right {
                padding: 0px 20px;
                min-width: 300px;
                max-width: 300px;
                height: 400px;

                .brand-card-left,
                .brand-card-right {
                    border: 6px solid #3ba7ff;
                }

                .brand-card-right {
                    border-color: #fbcc00;
                }

                .brands-left-link,
                .brands-right-link {
                    font-size: 3rem;
                }
            }

            .brands-seperator {
                height: 300px;
            }
        }
    }
}

@media (max-width: 689px) {
    .our-brands {
        .brands-header {
            margin-top: 10%;
        }
    }
}

@media (max-width: 612px) {
    .our-brands {
        .brands-main {
            padding: 40px;
            .brand-card-container {
                width: 70%;
                height: 80%;

                // *** Adding space between carousel slides *** //
                .slick-slide > div {
                    margin: 0 10px;
                }
                .slick-list {
                    margin: 0 -10px;
                }
                // *** *** //

                .brand-right-link-mobile {
                    text-decoration: none;
                }

                .brands-left,
                .brands-right {
                    display: flex !important;
                    flex-direction: column;

                    .brand-card-left,
                    .brand-card-right {
                        border: 8px solid #fbcc00;
                        border-radius: 22px;

                        .brand-card-image {
                            width: 100%;
                            height: 80%;
                        }
                    }

                    .brand-card-left {
                        border: 8px solid #3ba7ff;
                    }

                    .brands-right-link,
                    .brands-left-link {
                        font-size: 3rem;
                        text-align: center;
                        width: 100%;
                        text-transform: uppercase;
                        text-decoration-line: underline;
                        text-decoration-thickness: 2px;
                        text-underline-offset: 6px;
                    }

                    .brands-right-link {
                        color: #fbcc00;
                    }

                    .brands-left-link {
                        color: #3ba7ff;
                    }
                }

                .slick-arrow {
                    height: 200px;
                    width: 8px;
                    background-color: rgba(255, 255, 255, 0.224);
                    border-radius: 50%;

                    animation: arrow-glow 2s infinite;
                }

                .slick-disabled {
                    display: none !important;
                }

                .slick-arrow::before {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .our-brands {
        .brands-main {
            gap: 10px;

            .brands-left-inactive {
                min-width: 80px !important;
            }
            .brands-right-inactive {
                min-width: 100px !important;
                max-width: 100px !important;
            }

            .brands-left,
            .brands-right {
                min-width: 250px;
                max-width: 250px;

                .brands-left-link,
                .brands-right-link {
                    font-size: 2.6rem;
                    margin-top: 1rem;
                }
            }
        }
    }

    .button_right,
    .button_left {
        height: 40px;
        width: 40px;
    }
}

@media (max-width: 500px) {
    .our-brands {
        .brands-main {
            .brands-right,
            .brands-left {
                padding-left: 0px;
                padding-right: 0px;
            }

            .brands-right-inactive {
                min-width: 80px !important;
                max-width: 80px !important;
            }
        }
    }
}

@media (max-width: 460px) {
    .our-brands {
        .brands-header {
            margin-top: 20%;

            .brands-title-first,
            .brands-title-second {
                font-size: 2rem;
            }
        }

        .brands-main {
            .brand-card-container {
                width: 90%;
            }
        }
    }
}

@media (max-width: 360px) {
    .our-brands {
        .brands-main {
            .brands-left,
            .brands-right {
                min-width: 220px;
                max-width: 220px;
            }
        }
    }
}

// Slick arrow glowing animation

@keyframes arrow-glow {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}
