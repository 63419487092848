.teasers {
    display: flex;

    .teaser_icon_container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .teaser_icon_container .teaser_icon:nth-child(1) {
        margin-right: 10px

    }

    .teaser_icon_container .teaser_icon:nth-child(2) {
        margin-left: 10px;
    }

    .teaser_icon {
        font-size: 44px;
        margin-top: 50px;
        cursor: pointer;
    }
}

// @media (max-width: 1206px) {
//     .teasers {
//         .teaser_icon_container .teaser_icon:nth-child(1) {
//             margin-left: 180px;
//         }

//         .teaser_icon_container .teaser_icon:nth-child(2) {
//             margin-right: 180px;
//         }
//     }
// }

// @media (max-width: 898px) {
//     .teasers {
//         .teaser_icon_container .teaser_icon:nth-child(1) {
//             margin-left: 128px;
//         }

//         .teaser_icon_container .teaser_icon:nth-child(2) {
//             margin-right: 128px;
//         }
//     }
// }

// @media (max-width: 495px) {
//     .teasers {
//         .teaser_icon_container .teaser_icon:nth-child(1) {
//             margin-left: 0px;
//         }

//         .teaser_icon_container .teaser_icon:nth-child(2) {
//             margin-right: 0px;
//         }
//     }
// }
