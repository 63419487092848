.orders {
    display: flex;

    .content_first {
        .highlight_yellow {
            font-weight: bold;
    
            a {
                color: #fbcc00;
                text-decoration: none;
            }
        }

        div {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    button {
        border-color: gold;
        border-radius: 14px;
        width: fit-content;
        letter-spacing: 4px;
        padding: 10px;
        margin-top: 20px;
        color: #fbcc00;
        background-color: rgba(6, 30, 44, 0.753);
        font-weight: bold;
        cursor: pointer;
        text-align: center;

        span {
            color: inherit;
        }

        i {
            color: #fbcc00;
            font-family: sans-serif;
            font-weight: 600;
            margin-right: -2px;
            margin-left: -3px;
        }
    }
}

@media (max-width: 1850px) {
    // .orders {
    //     .content_first {
            
    //     }
    // }
}

@media (max-width: 1669px) {
    .orders {
        button {
            margin-top: 0px;
        }
    }
}


@media (max-width: 1469px) {
    .orders {
        button {
            margin-top: 20px;
        }
    }
}

@media (max-width: 1355px) {
    .orders {
        button {
            margin-top: 20px;
        }
    }
}
