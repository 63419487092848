.shop {
    display: flex;

    .content_first {

        h3 {
            color: black;
            margin-bottom: -30px;
            margin-top: -20px;
        }

        .shop_icon_container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;

            .shop_icon {
                font-size: 44px;
                margin-top: 30px;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 1669px) {
    .shop {
        .content_first {
            .shop_icon_container {
                .shop_icon {
                    font-size: 24px;
                    margin-top: 0px;
                }
            }
        }
    }
}

@media (max-width: 1545px) {
    .shop {
        .content_first {
            .shop_icon_container {
                .shop_icon {
                    margin-top: 20px;
                }
            }
        }
    }
}

@media (max-width: 1371px) {
    .shop {
        .content_first {
            .shop_icon_container {
                .shop_icon {
                    font-size: 44px;
                    margin-top: 40px;
                }
            }
        }
    }
}