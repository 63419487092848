.footer_container {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .shop_icon {
        font-size: 30px;
        cursor: pointer;
    }


    .footer_content {
        font-size: 20px;
        color: rgb(161, 161, 161);
        // border-bottom: 1px solid white;
    }
}

@media (max-width: 568px) {
    .footer_container {
        .footer_content {
            font-size: 16px;
        }
    }
}

@media (max-width: 400px) {
    .footer_container {
        .footer_content {
            font-size: 14px;
        }
    }
}