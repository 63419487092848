
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,700;1,100;1,200;1,900&family=Roboto+Slab:wght@100;200;400;500;600;700;800&display=swap');

/*
    BLUES - #116cb7, #3ba7ff, #9ed4ff
    YELLOW - #fbcc00

*/

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    color: white;
    /* border: 1px solid red; */
    /* font-family: 'Roboto Slab', serif; */
}

html,body{
    /* margin: 0; */
    font-size: 62.5%;
    background-color: black;
}
