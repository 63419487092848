.main_content {
    margin-left: 80px;

    .heading_container {
        margin-left: 180px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 100px;
        top: 280px;
        width: 430px;
        height: 430px;
        border-radius: 50%;
        background-color: #9ed4ff;
        z-index: 0;

        .heading_gimmick {
            position: absolute;
            height: 350px;
            width: 81px;
            background-color: black;
            z-index: -1;
            left: -1px;
        }

        .heading {
            background: #116cb7;
            width: 400px;
            height: 400px;
            border: 10px solid #fbcc00;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            img {
                width: 80%;
                top: 40px;
                height: auto;
                position: absolute;
                z-index: 0;
            }

            h2 {
                font-size: 45px;
                color: black;
                margin-top: 18px;
                width: 90%;
                text-align: center;
                z-index: 1;
            }
        }
    }

    .content {
        background-color: #3ba7ff;
        padding-top: 40px;
        margin: 280px 300px 200px 300px;
        padding-bottom: 80px;
        border-radius: 14px;
        height: 430px;

        p {
            font-size: 1.8rem;
            color: black;
            padding: 0 60px;
            text-align: justify;
            height: 100%;

            .content_first {
                color: inherit;
                width: fit-content;
                margin-left: 340px;
            }

            .content_second {
                color: inherit;
                margin-top: 30px;
            }
        }
    }
}

// Queries //
@media (min-width: 2000px) {
    .main_content {
        .content {
            p {
                .content_first {
                    margin-top: 50px;
                }
            }
        }
    }
}

@media (max-width: 1692px) {
    .main_content {

        .heading_container {
            top: 180px;
        }

        .content {
            margin-right: 200px;
            margin-top: 180px;
        }
    }
}


@media (max-width: 1590px) {
    .main_content {
        .content {
            height: 430px;
            margin-top: 180px;
        }
    }
}

@media (max-width: 1544px) {
    .main_content {
        .heading_container {
            position: relative;
            left: -80px;

            .heading_gimmick {
                position: absolute;
                height: 350px;
                width: 80px;
                background-color: black;
                z-index: -1;
                left: 0px;
            }
        }

        .content {
            margin-right: 200px;
            margin-left: 200px;
            margin-top: -260px;
            height: 490px;
            p {
                .content_first {
                    margin-left: 300px;
                    margin-top: 0px;
                }
            }
        }
    }
}


@media (max-width: 1438px) {
    .main_content {
        .content {
           height: 500px;
        }
    }
}

@media (max-width: 1355px) {
    .main_content {
        .content {
            height: 550px;

            p {
                height: 600px;
            }
        }
    }
}

@media (max-width: 1274px) {
    .main_content {
        .content {
            height: 600px
        }
    }
}


@media (max-width: 1217px) {
    .main_content {

        .heading_container {
            left: 100px;
            overflow: hidden;
            top: 90px;
        }

        .content {
            margin-right: 0px;
            width: 600px;
            height: 900px;
            margin-top: -300px;
            p {
                width: 100%;
                .content_first {
                    margin-left: 0px;
                    margin-top: 400px;
                }
            }
        }
    }
}


@media (max-width: 1000px) {
    .main_content {

        .heading_container {
            left: 0px;
            top: 40px;

            transform: scale(.8);
        }

        .content {
            margin-left: 100px;

            p {
                margin-top: -40px;
            }
        }
    }
}

@media (max-width: 898px) {
    .main_content {
        .heading_container {
            left: -30px;
        }
        .content {
            width: 500px;
            margin-left: 120px;
            height: 1000px;
        }
    }
}

@media (max-width: 800px) {
    .main_content {
        .heading_container {
            left: -50px;
            top: 20px;
            transform: scale(.6);
        }
        .content {
            margin-left: 50px;
            width: 600px;
            height: 800px;
            margin-top: -200px;

            p {
                .content_first {
                    margin-top: 200px;
                }
            }
        }
    }
}


@media (max-width: 749px) {
    .main_content {
        width: 420px;
        .heading_container {
            left: -140px;
            top: 5px;
        }

        .content {
            width: 420px;
            height: 950px;
            margin-top: -300px;

            p {
                margin-top: 260px;
            }
        }
    }
}

@media (max-width: 560px) {
    .main_content {
        width: 360px;
        .heading_container {
            left: -200px;
            top: 24px;
        }
        .content {
            margin-left: 20px;
            margin-top: -320px;
            width: 360px;
            height: 1090px;

            p {
                margin-top: 300px;
            }
        }
    }
}

@media (max-width: 495px) {
    .main_content {
        width: 280px;
        .heading_container {
            left: -210px;
            top: 30px;
            transform: scale(.5);
        }
        .content {
            margin-left: 50px;
            margin-top: -242px;
            width: 280px;
            height: 750px;

            p {
                width: 100%;
                font-size: 14px;
                margin-top: 200px;
                padding: 0 20px;
            }
        }
    }
}

@media (max-width: 427px) {
    .main_content {

        width: 260px;

        .heading_container {
            left: -255px;
            top: 0px;
            transform: scale(.5);

            .heading_gimmick {
                top: 40px;
            }
        }

        .content {
            margin-left: 10px;
            width: 260px;
            height: 840px;
            margin-top: -300px;

            p {
                margin-top: 10px;
            }
        }
    }
}

@media (max-width: 376px) {
    .main_content {

        .heading_container {
            left: -276px;
            top: 30px;

            .heading_gimmick {
                left: 0px;
                width: 40px
            }
        }

        .content {
            margin-left: -10px;
            height: 700px;
            margin-top: -250px;

            p {
                width: 260px;
                font-size: 12px;
                margin-top: 240px;
                padding: 0 20px;
            }
        }
    }
}