.water_mark_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 0;
    position: fixed;

    .water_mark_lines {
        border: 2px solid #9ed4ff;
        background-color: #9ed4ff;
        height: 300px;
        width: 1px;
    }

    .water_mark {
        font-weight: bold;
        color: #9ed4ff;
        margin: 60px 0;
        font-size: 16px;
    
        transform: rotate(270deg);
    }
}

// Queries //

@media (max-width: 595px) {
    .water_mark_container {
        width: 60px;

        .water_mark_lines {
            height: 200px;
        }
    }
}

@media (max-width: 376px) {
    .water_mark_container {
        width: 60px;

        .water_mark_lines {
            height: 100px;
        }
    }
}

@media (max-height: 860px) {
    .water_mark_container {
        .water_mark_lines {
            height: 200px;
        }
    }
}

@media (max-height: 546px) {
    .water_mark_container {
        .water_mark_lines {
            height: 150px
        }
    }
}

@media (max-height: 480px) {
    .water_mark_container {
        .water_mark_lines {
            height: 120px
        }
    }
}

@media (max-height: 403px) {
    .water_mark_container {
        .water_mark_lines {
            height: 80px;
        }
    }
}