.aim {
    display: flex;

    .heading_container {
        top: 100px !important;
    }

    .content {
        height: fit-content !important;
        margin-top: 100px !important;

        .content_first {
            margin-top: 40px;
            .content_para_one {
                color: black;
            }
        }

        .content_second {
            margin-top: 60px !important;
            .content_para_two {
                color: black;
                display: none;
            }            
        }
    }
}

@media (max-width: 1669px) {
    .aim {
        .content {
            .content_second {
                margin-top: 30px !important;
            }
        }
    }
}

@media (max-width: 1544px) {
    .aim {
        .heading_container {
            top: 80px !important;
        }
        .content {
            margin-top: -350px !important;
        }
    }
}

@media (max-width: 1400px) {
    .aim {
        .content {
            
            .content_second {
                margin-top: 0px !important;
            }
        }
    }
}

@media (max-width: 1355px) {
    .aim {
        .content {
            height: 1600px !important;

            .content_first {
                .content_para_one {
                    display: none;
                }
            }

            .content_second {
                margin-top: 60px !important;
                .content_para_two {
                    display: inline;
                }
            }
        }
    }
}

@media (max-width: 1217px) {
    .aim {
        .content {
            height: 2100px !important;
            .content_first {
                margin-top: 500px !important;

            }

            .content_second {
                margin-top: 0px !important;
            }
        }
    }
}

@media (max-width: 1000px) {
    .aim {
        .heading_container {
            top: 40px !important;
        }
    }
}


@media (max-width: 899px) {
    .aim {
        .content {
            height: 2300px !important;

            .content_first {
                margin-top: 400px !important;
            }
        }
    }
}
@media (max-width: 800px) {
    .aim {
        .heading_container {
            top: -6px !important;
        }
        .content {
            height: 2000px !important;
        }
    }
}

@media (max-width: 748px) {
    .aim {
        .content {
            height: 2700px !important;

            .content_first {
                margin-top: 300px !important;
            }
        }
    }
}

@media (max-width: 560px) {
    .aim {
        .content {
            height: 3300px !important;
        }
    }
}

@media (max-width: 495px) {
    .aim {
        .heading_container {
            top: -26px !important;
        }

        .content {
            height: 2200px !important;

            .content_first {
                margin-top: 240px !important;
            }
        }
    }
}

@media (max-width: 376px) {
    .aim {
        .content {
            height: 1700px !important;
        }
    }
}